import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import TelecommIcon from "../images/svg/Telecoms.svg"
import IntegrationIcon from "../images/svg/Integration.svg"

import TelecommunicationsList from "../components/services/telecommunicationsServices"
import IntegrationList from "../components/services/integrationServices"

import { FaArrowCircleRight } from "react-icons/fa"

const ServicesPage = () => (
    <Layout>
        <Seo
            title="Services"
            description="Martindale Technologies Inc. list of services"
        />
        <section className="bg-grey services-section">
            <section className="section-center">
                <h2 className="section-title">Our Services</h2>
                <p className="services-text">
                    One of the pioneers in providing the most advanced state of the art
                    technology in telecommunications systems. Martindale Technologies,
                    Inc. also has a proven track record in establishing data, voice, video
                    network on various high-risk industrial and residential establishments
                    ie. port area, oil & gas, airports, and rapid transit stations.
                </p>

                <div className="services-header">
                    <TelecommIcon className="services-icon"></TelecommIcon>
                    <h3>Telecommunications</h3>
                </div>
                <div className="services-wrapper">
                    <TelecommunicationsList></TelecommunicationsList>
                </div>

                <div className="services-header">
                    <IntegrationIcon className="services-icon"></IntegrationIcon>
                    <h3>Systems Solutions & Integration</h3>
                </div>
                <div className="services-wrapper">
                    <IntegrationList></IntegrationList>
                </div>
            </section>

            <div className="index-second contact-div">
                <Link to="/contact/">
                    <button className="btn">
                        Get in Touch
                        <FaArrowCircleRight className="btn-icon" />
                    </button>
                </Link>
            </div>
        </section>
    </Layout>
)

export default ServicesPage
