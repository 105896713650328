import React from "react"
import ListButton from "./ListButton"


const IntegrationServices = () => {
  const services = [
    {
      id: 4,
      title: "Data Center Construction"
    },
    {
      id: 6,
      title: "Data Center Management"
    },
    {
      id: 1,
      title: "Wired & Wireless Systems"
    },
    {
      id: 2,
      title: "Port Area & Airport CCTV Systems"
    },
    {
      id: 3,
      title: "Structured Cabling System"
    },
    {
      id: 5,
      title: "Building Management Systems"
    },
  ]

  return (
    <section className="service-list">
      {services.map((service, index) => {
        return <ListButton key={service.id} title={service.title} description={service.description}></ListButton>
      })}

    </section>
  )
}

export default IntegrationServices
