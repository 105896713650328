import React from "react"

import ListButton from "./ListButton"

// const query = graphql`
//   {
//     list: allStrapiTelecommunicationServices {
//       nodes {
//         strapiId
//         Description
//         Title
//         id
//       }
//     }
//   }
// `

const TelecommunicationsServices = () => {
  const services = [
    {
      id: 1,
      title: "Site Acquisition (SAQ)"
    },
    {
      id: 2,
      title: "Survey Design and Documentation"
    },
    {
      id: 3,
      title: "Tower Erection (CME)"
    },
    {
      id: 4,
      title: "Telecom Installation (TI)"
    },
    {
      id: 5,
      title: "Integration & Commisioning"
    },
    {
      id: 6,
      title: "Field Testing & Optimization"
    },
    {
      id: 7,
      title: "Microwave Installation"
    },
    {
      id: 8,
      title: "IBS Installation"
    },
    {
      id: 9,
      title: "Cell Site Maintenance"
    },
    {
      id: 10,
      title: "Passive Support Services"
    },
  ]
  return (
    <section className="service-list">
      {services.map((service, index) => {
        return <ListButton key={service.id} title={service.title} description={service.Description}></ListButton>
      })}

    </section>
  )
}

export default TelecommunicationsServices
