import React, { Component } from "react"
import { FaArrowCircleRight } from "react-icons/fa"

class ListButton extends Component {
    constructor(props) {
        super(props)

        this.state = {
            clicked: false,
        }
    }

    toggleDisplay = () => { }

    render() {
        let item_class = this.state.clicked
            ? "service-item si-s"
            : "service-item si-h"
        let btn_class = this.state.clicked ? "btn-c-red" : "btn-c-gray"
        let btn_icon_class = this.state.clicked
            ? "icon-service-s"
            : "icon-service-h"
        let description_class = this.state.clicked
            ? "description s"
            : "description h"

        return (
            <div className={item_class}>
                <button className={btn_class} onClick={this.toggleDisplay.bind(this)}>
                    <FaArrowCircleRight className={btn_icon_class}></FaArrowCircleRight>
                    {this.props.title}
                </button>
                <div className={description_class}>
                    <div></div>
                    <p>{this.props.description}</p>
                </div>
            </div>
        )
    }
}

export default ListButton
